import React from 'react';
import Layout from '../components/Layout';

const DuesPage = () => {
    return (
        <Layout>
            <div className="container-fluid p-0" id="page-top">
                <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
                    <div className="w-100">
                        <h1 className="mb-0">Give <span className="text-primary">Today</span></h1>
                        <h2 className="mb-5">Lambda Iota Alumni Association</h2>
                        <p className="mb-5">The Lambda Iota Alumni Association is collecting membership dues and funds for the 50th
                            Anniversary!</p>

                        <table className="table">
                            <thead>
                                <tr>
                                    <td colSpan="4">
                                        <div align="center">Please select from the following giving options:</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Recurrence</th>
                                    <th scope="col">Make Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">50th Sustainer</th>
                                    <td>$50.00</td>
                                    <td>Monthly</td>
                                    <td>
                                        <a className="btn btn-success"
                                            href="/dues-recurring-form?priceId=price_1H5ItEAAFUDq0L5DVg5p8zHL"
                                            role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">1973 Founder</th>
                                    <td>$19.73</td>
                                    <td>Monthly</td>
                                    <td>
                                        <a className="btn btn-success"
                                            href="/dues-recurring-form?priceId=price_1H5It8AAFUDq0L5DVysoiUt5"
                                            role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Supporter</th>
                                    <td>$10.00</td>
                                    <td>Monthly</td>
                                    <td>
                                        <a className="btn btn-success"
                                            href="/dues-recurring-form?priceId=price_1H5It2AAFUDq0L5DRtiguQx9"
                                            role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Annual Membership Dues</th>
                                    <td>$50.00</td>
                                    <td>One-Time</td>
                                    <td>
                                        <a className="btn btn-success" href="/dues-single?amount=50" role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">One-Time Donation</th>
                                    <td>Custom</td>
                                    <td>One-Time</td>
                                    <td>
                                        <a className="btn btn-success" href="/dues-single" role="button">Give</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4">
                                        <div align="center">
                                            Monthly payments will run until April 30, 2023.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4">
                                        <div align="center">
                                            <a className="nav-link js-scroll-trigger" href="/pay-offline">Paypal / Check / Offline
                                                Payment
                                                Instructions</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default DuesPage;
